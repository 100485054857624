/* footer */
footer {
    width: 100%;
    background-color: #fff;
    font-size: 12px;
    color: #4c4c4c;
    padding: 15px 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 1;
    border-top: 1px solid #039eff;
    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &:before, &:after {
            display: none;
        }
    }
    ul {
        @include ul-default;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }
    p {
        @include inline-block;
        margin: 0;
    }
    a {
        color: #5c5d61;
        font-size: 12px;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .footer__cts {
        width: 100%;
        a {
            pointer-events: none;
        }
    }
    .f--link a {
        pointer-events: auto;
        i {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 1600px) {
        width: 100%;
        padding: 15px 15px;
    }
    @media screen and (max-width: 1440px) {
        padding: 15px 15px;
    }
    @media screen and (max-width: 1024px) {
        position: relative;
        ul {
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            li {
                margin-bottom: 5px;
            }
        }
        .footer__cts {
            a {
                pointer-events: auto;
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 10px 0px;
    }
}
/* footer */