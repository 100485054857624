/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'GothaProReg';
    src: url('../fonts/GothaProReg.eot');
    src: url('../fonts/GothaProReg.eot') format('embedded-opentype'),
         url('../fonts/GothaProReg.woff2') format('woff2'),
         url('../fonts/GothaProReg.woff') format('woff'),
         url('../fonts/GothaProReg.ttf') format('truetype'),
         url('../fonts/GothaProReg.svg#GothaProReg') format('svg');
}
@font-face {
    font-family: 'GothaProBol';
    src: url('../fonts/GothaProBol.eot');
    src: url('../fonts/GothaProBol.eot') format('embedded-opentype'),
         url('../fonts/GothaProBol.woff2') format('woff2'),
         url('../fonts/GothaProBol.woff') format('woff'),
         url('../fonts/GothaProBol.ttf') format('truetype'),
         url('../fonts/GothaProBol.svg#GothaProBol') format('svg');
}
@font-face {
    font-family: 'UbuntuR';
    src: url('../fonts/UbuntuR.eot');
    src: url('../fonts/UbuntuR.eot') format('embedded-opentype'),
         url('../fonts/UbuntuR.woff2') format('woff2'),
         url('../fonts/UbuntuR.woff') format('woff'),
         url('../fonts/UbuntuR.ttf') format('truetype'),
         url('../fonts/UbuntuR.svg#UbuntuR') format('svg');
}
@font-face {
    font-family: 'rainTungstenM';
    src: url('../fonts/rainTungstenM.eot');
    src: url('../fonts/rainTungstenM.eot') format('embedded-opentype'),
         url('../fonts/rainTungstenM.woff2') format('woff2'),
         url('../fonts/rainTungstenM.woff') format('woff'),
         url('../fonts/rainTungstenM.ttf') format('truetype'),
         url('../fonts/rainTungstenM.svg#rainTungstenM') format('svg');
}

$regular: 'Arial';
$bold: 'Arial';
$ubuntuR: 'Arial';
$rainTM: 'Arial';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    color: #4c4c4c;
    padding-top: 150px;
	margin: 0;
    @media screen and (max-width: 1440px) {
        padding-top: 125px;
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 0;
    }
    @media screen and (max-width: 767px) {
        padding-top: 70px;
    }
}

.preload {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: none;
}

h1 {
	font-family: $bold;
	font-size: 30px;
    font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	color: #272727;
	line-height: 1;
	margin: 0 0 40px;
	@media screen and (max-width: 1199px) {
		font-size: 26px;
	}
    @media screen and (max-width: 991px) {
        font-size: 24px;
    }
	@media screen and (max-width: 768px) {
		font-size: 22px;
	}
}
h2 {
	font-family: $bold;
	font-size: 28px;
    font-weight: bold;
	color: #272727;
    margin: 0 0 40px;
	a {
		font-family: $bold;
		font-size: 28px!important;
		color: #272727;
	}
	@media screen and (max-width: 1199px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
    @media screen and (max-width: 991px) {
        font-size: 22px;
        a {
            font-size: 22px!important;
        }
    }
	@media screen and (max-width: 768px) {
		font-size: 20px;
		a {
			font-size: 20px!important;
		}
	}
}
h3 {
	font-family: $bold;
    font-weight: bold;
	font-size: 24px;
	color: #272727;
	@media screen and (max-width: 1199px) {
		font-size: 22px;
	}
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
}
h4 {
	font-family: $bold;
    font-weight: bold;
	font-size: 22px;
	color: #272727;
    @media screen and (max-width: 1199px) {
        font-size: 20px;
    }
    @media screen and (max-width: 991px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
    font-weight: bold;
	font-size: 18px;
	color: #272727;
}
h6 {
	font-family: $bold;
    font-weight: bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #272727;
}
strong {
    font-family: $bold;
    color: #272727;
}
p {
    margin: 10px 0;
}
a {
    color: #00acff;
	transition: 350ms;
	text-decoration: underline;
    span {
        color: #4c4c4c!important;
    }
	&:focus {
		color: #00acff;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #272727;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        min-height: 43px;
        width: 100%;
		background-color: #fff;
		font-family: $regular;
		font-size: 14px;
		padding: 12px 15px;
        border: 1px solid #d6dae2;
        line-height: 1;
        outline: none;
        margin-bottom: 18px;
	}
}
.main {
    margin-bottom: 65px;
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
}
.btn {
	display: table;
	width: auto!important;
    min-width: 150px;
	background-color: #0099ec!important;
	font-family: $regular!important;
	font-size: 14px;
	color: #ffffff!important;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #0099ec;
	padding: 13px 30px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #0099ec!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #0099ec!important;
	}	
}

.line--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 14px;
		padding-left: 28px;
		margin-bottom: 5px;
		&:before {
			content: '';
            width: 16px;
            height: 1px;
            background-color: #00acff;
			position: absolute;
			left: 0;
			top: 9px;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #304fbf;
		position: absolute;
		top: -2px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}

/* header */
header {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    letter-spacing: 2px;
    padding: 50px 0;
    z-index: 9;
    border-bottom: 1px solid #039eff;
    .container {
        position: relative;
        width: 1485px;
    }
    a {
        text-decoration: none;
    }

	/* header phones */
	
	/* header phones */

	/* header logo */
	
	/* header logo */

	/* header nav */
	.nav {
        @include inline-block;
        float: right;
        margin-right: 175px;
        line-height: 1;
        > ul {
            @include ul-default;
            > li {
                @include inline-block;
                margin-left: 30px;
                > a {
                    display: block;
                    font-size: 11px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #4c4c4c;
                    padding: 8px 0;
                    border: 1px solid transparent;
                    &:hover {
                        text-decoration: none;
                        border-bottom: 1px solid #00acff;
                    }
                }
                &.active > a {
                    color: #4c4c4c;
                    border-bottom: 1px solid #00acff;
                }
            }
        }
    }
	/* header nav */

	/* header langs */
	.langs {
        width: 45px;
        max-height: 28px;
        position: absolute;
        left: 15px;
        top: 0px;
        padding: 5px 12px 5px 0px;
        overflow: hidden;
        line-height: 1;
        transition: 400ms;
        &:before {
            content: '';
            width: 100%;
            height: 1px;
            background-color: #494949;
            position: absolute;
            top: 25px;
            left: 0;
        }
        &:after {
            content: '';
            border-left: 3.5px solid transparent;
            border-right: 3.5px solid transparent;
            border-top: 4px solid #494949;
            position: absolute;
            top: 12px;
            right: 2px;
        }
        ul {
            @include ul-default;
            li {
                display: block;
                a {
                    display: block;
                    font-size: 11px;
                    text-transform: uppercase;
                    padding: 5px 0;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &.active a {
                    color: #272727;
                }
            }
        }
        &:hover {
            max-height: 150px;
            background-color: #fff;
        }
    }
	/* header langs */

    &.header {
        .container {
            width: 1170px;
        }
        .logo {
            display: block;
            position: absolute;
            left: -210px;
            top: -10px;
        }
    }

    @media screen and (max-width: 1680px) {
        &.header {
            .logo {
                left: -135px;
            }
        }
    }

    @media screen and (max-width: 1485px) {
        .container {
            width: auto;
        }
        .langs {
            left: 15px;
        }
        .nav {
            margin-right: 0;
        }
        &.header {
            .logo {
                left: 15px;
            }
            .langs {
                left: 180px;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        padding: 35px 0;
    }
    @media screen and (max-width: 1170px) {
        &.header {
            .container {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .nav {
            > ul > li {
                margin-left: 15px;
            }
        }
        &.header {
            .langs {
                left: 160px;
            }
            .nav {
                > ul > li {
                    margin-left: 10px;
                }
            }
        }
    }
	
	@media screen and (max-width: 767px) {
        min-height: 70px;
        padding: 70px 0 0;
        border-bottom: 1px solid #010001;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 15px;
			top: -50px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #010001;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
        .nav {
            display: none;
            width: 100%;
            text-align: center;
            float: none;
            border-top: 1px solid #010001;
            padding-bottom: 10px;
            > ul {
                width: 100%;
                > li {
                    display: table;
                    margin: 0 auto;
                    &:nth-of-type(1) {
                        margin-top: 15px;
                    }
                }
            }
        }
        .langs {
            right: 15px;
            left: auto;
            top: -54px;
            padding-left: 5px;
        }
        &.header {
            .logo {
                width: 120px;
                position: fixed;
                left: 0;
                right: 0;
                top: 8px;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
            .langs {
                left: auto;
                right: 15px;
            }
            .nav > ul > li {
                margin-left: auto;
            }
        }
	}
}
/* header */