/* page content */
.page {
    padding-bottom: 87px;
    > .container {
        width: 1260px;
        position: relative;
        padding-left: 410px;
        padding-top: 15px;
        min-height: calc(100vh - 220px);
        &:after {
            content: '';
            display: block;
            width: 3px;
            background-color: #000;
            position: absolute;
            top: 0;
            bottom: 0;
            right: calc(100% - 390px);
        }
    }
    .container {
        width: 100%;
    }
	.news1, .news {
		.date {
			color: #00acff;
		}
	}
	.page_nav {
		.active {
			color: #00acff;
            text-decoration: underline;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #00acff;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    h1 {
        display: block;
        min-width: 390px;
        white-space: nowrap;
        background-color: #000000;
        text-align: right;
        color: #fff;
        padding: 12px 20px 12px 20px;
        position: absolute;
        right: calc(100% - 390px);
        top: 0;
        line-height: 1;
    }
    h6 {
        margin: 18px;
    }
    @media screen and (max-width: 1440px) {
        h1 {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 1200px) {
        > .container {
            width: 100%;
        }
    }
	@media screen and (max-width: 1170px) {
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        > .container {
            padding-top: 0;
            padding-left: 15px;
            &:after {
                display: none;
            }
        }
        h1 {
            display: table;
            text-align: left;
            right: 0;
            position: relative;
            margin: 25px 0 25px;
        }
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 40px;
        > .container {
            min-height: 0px;
        }
        h1 {
            min-width: 0px;
        }
    }
    
	@media screen and (max-width: 580px) {
        padding-bottom: 20px;
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #3361eb;
			@include inline-block;
			a {
				text-decoration: none;
			}
			&:after {
				content: '/';
				margin: 0px 2px 0px 7px;
				color: #282727;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

.page--block {
    display: flex;
    .col {
        width: 50%;
        position: relative;
        padding-right: 25px;
        &:after {
            content: '';
            width: 3px;
            background-color: #000;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
        }
        &:last-of-type:after {
            display: none;
        }
    }
    @media screen and (max-width: 480px) {
        flex-direction: column;
        .col {
            width: 100%;
            padding-right: 0;
            &:after {
                display: none;
            }
        }
    }
}

/* page exp */
.exp {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    ul {
        @include ul-default;
    }
    .exp__left {
        width: 222px;
        li {
            display: block;
            margin-bottom: 5px;
            a {
                display: block;
                font-size: 15px;
                color: #4c4c4c;
                padding: 8px 14px;
                border: 1px solid transparent;
                position: relative;
                text-decoration: none;
                z-index: 1;
                line-height: 1;
                position: relative;
                &:after {
                    content: '';
                    background-color: #fff;
                    position: absolute;
                    left: -1px;
                    top: -1px;
                    right: 8px;
                    bottom: -1px;
                    border: 1px solid #d1d1d1;
                    transition: 300ms;
                    z-index: -1;
                }
                &:hover {
                    background-color: #fff;
                    color: #272727;
                    text-decoration: none;
                    border: 1px solid #d1d1d1;
                    border-right: 1px solid transparent;
                    &:after {
                        right: -1px;
                        border-right: 1px solid transparent;
                    }
                }
            }
            &.active a {
                background-color: #fff;
                color: #272727;
                border: 1px solid #d1d1d1;
                border-right: 1px solid transparent;
                z-index: 2;
                &:after {
                    right: -1px;
                    border-right: 1px solid transparent;
                }
                
            }
        }
    }
    .exp__right {
        display: none;
        min-height: 109px;
        background: #fff;
        width: calc(100% - 222px);
        padding: 30px 20px 21px 20px;
        margin-left: -1px;
        border: 1px solid #d6d6d6;
        .line--list {
            @include inline-block;
            width: 49%;
            padding-right: 10px;
            box-sizing: border-box;
            li {
                font-size: 15px;
                color: #000000;
            }
        }
        .exp__right__title {
            width: 200px;
            font-size: 14px;
            color: #272727;
            display: inline-block;
            padding: 5px 0 0 0px;
            img {
                display: block;
                max-width: 100%;
            }
            ul {
                position: relative;
                font-size: 12px;
                color: #272727;
                padding-left: 10px;
                border-left: 3px solid #000;
                li a {
                    color: #272727;
                    text-decoration: none;
                }
            }
        }
        .exp__right__block {
            @include inline-block;
            width: calc(100% - 215px);
            font-size: 14px;
            color: #272727;
            padding: 0 0 0 25px;
            p {
                margin: 10px 0;
                line-height: 1.8;
            }
        }
        .left {
            @include inline-block;
            width: 200px;
            ul li {
                a {
                    display: block;
                    color: #272727;
                    text-decoration: none;
                    padding: 5px 15px;
                    &:hover {
                        background-color: #000;
                        color: #fff;
                    }
                }
                &.active a {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
        .right {
            @include inline-block;
            width: calc(100% - 225px);
            height: 300px;
            border-left: 2px solid #000;
            padding-left: 20px;
            margin: 0 0 0 20px;
            overflow: hidden;
            opacity: 0;
            transition: 500ms;
            &.active {
                opacity: 1;
            }
            .block {
                display: none;
                height: 100%;
                overflow: auto;
            }
            p {
                margin: 0;
            }
            ul li {
                padding: 20px 0;
                border-top: 1px solid #e2e4e7;
                &:nth-of-type(1) {
                    padding-top: 0;
                    border: none;
                }
            }
        }
    }
    &.team {
        .exp__right {
            min-height: 0px;
            top: 35px;
            padding-left: 20px;
        }
    }
    &.mobile {
        display: none;
        .exp__left {
            display: block;
            font-size: 15px;
            color: #4c4c4c;
            padding: 8px 20px 8px 14px;
            border: 1px solid #d1d1d1;
            position: relative;
            text-decoration: none;
            z-index: 1;
            line-height: 1;
            position: relative;
            margin-bottom: 5px;
            &:after {
                content: '';
                width: 0px;
                height: 0px;
                position: absolute;
                border-left: 3.5px solid transparent;
                border-right: 3.5px solid transparent;
                border-top: 4px solid #00acff;
                top: 14px;
                right: 6px;
            }
            &.active:after {
                border-left: 3.5px solid transparent;
                border-right: 3.5px solid transparent;
                border-bottom: 4px solid #494949;
                border-top: none;
                top: 13px;  
            }
        }
        .exp__right {
            position: relative;
            top: 0;
            margin-bottom: 5px;
        }
    }
    @media screen and (max-height: 1440px) {
        .exp__right {
            .exp__right__block {
                p {
                    line-height: 1.5;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .exp__left {
            width: 190px;
        }
        .exp__right {
            width: calc(100% - 189px);
            padding: 20px ;
            .exp__right__title {
                width: 140px;
                padding-left: 0px;
            }
            .exp__right__block {
                width: calc(100% - 150px);
            }
        }
    }
    @media screen and (max-width: 991px) {
        .exp__left {
            ul li a {
                font-size: 14px;
            }
        }
        &.team {
            .exp__right {
                padding-left: 20px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .exp__right {
            .left {
                ul li a {
                    padding: 2px 5px;
                }
            }
            .right {
                width: calc(100% - 220px);
                margin-left: 10px;
                padding-left: 10px;
                ul li {
                    padding: 10px 0;
                }
            }
        }
    }
    @media screen and (max-width: 680px) {
        display: none;
        .exp__left {
            width: 100%;
            ul li {
                a {
                    &:after {
                        right: 0;
                    }
                    &:hover {
                        border: 1px solid #d1d1d1;
                    }
                }
                &.active a {
                    border: 1px solid #d1d1d1;
                }
                
            }
        }
        .exp__right {
            width: 100%;
            position: relative;
            padding: 15px;
            .line--list {
                width: 100%;
                padding: 0;
                li {
                    font-size: 14px;
                }
            }
        }
        &.team {
            .exp__right {
                top: 0;
            }
        }
        &.mobile {
            display: block;
            margin-bottom: 15px;
          .exp__right {
                min-height: 0px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .exp__right {
            .exp__right__title {
                width: 100%;
                border: none;
                padding: 0;
            }
            .exp__right__block {
                width: 100%;
                border: none;
                padding: 0;
                margin-top: 10px;
            }
            .left, .right {
                width: 100%;
            }
            .right {
                padding: 10px 0 0 0;
                margin-left: 0;
                margin-top: 10px;
                border: none;
                border-top: 1px solid #e2e4e7;
            }
        }
    }
}
/* page exp */

/* page news */
.news {
    .news__block {
        max-width: 650px;
        position: relative;
        margin-bottom: 20px;
        .date {
            display: block;
            font-size: 10px;
            color: #999999;
            position: absolute;
            top: 5px;
            left: -90px;
        }
        a {
            font-size: 16px;
        }
    }
    &.news--item {
        .news__block {
            max-width: 720px;
            padding-top: 20px;
            .date {
                left: 0;
                top: 0;
            }
            img {
                display: block;
                max-width: 100%;
                margin-bottom: 25px;
            }
        }
        .pagination {
            width: 720px;
            margin-left: 0;
            li {
                margin-top: -2px;
                a {
                    color: #0eb1ff;
                    padding-bottom: 1px;
                    border-bottom: 1px solid #0eb1ff;
                    &:hover {
                        border-bottom: 1px solid transparent; 
                    }
                }
                &.prev,&.next {
                    margin-top: 5px;
                    a {
                        border: none!important;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .news__block {
            .date {
                display: block;
                position: relative;
                left: 0;
                top: 0;
            }
            a {
                font-size: 14px;
            }
        }
        &.news--item {
            .pagination {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 420px) {
        &.news--item {
            .pagination {
                ul li {
                    &.next, &.prev {
                        @include inline-block;
                    }
                    &.prev {
                        float: left;
                    }
                    &.next {
                        float: right;
                    }
                }
            }
        }
    }
}
/* page news */

/* page pagination */
.pagination {
    width: 730px;
    text-align: center;
    margin-left: -90px;
    ul {
        @include ul-default;
        width: 100%;
        li {
            @include inline-block;
            margin: 5px 15px;
            a {
                display: block;
                font-family: $bold;
                font-weight: bold;
                font-size: 16px;
                color: #272727;
                text-decoration: none;
                &:hover {
                    color: #0eb1ff;
                }
            }
            &.active a {
                color: #0eb1ff;
            }
            &.prev, &.next {
                margin-top: 10px;
                a {
                    width: 65px;
                    height: 14px;
                    background: url(../img/arrow.png) no-repeat center center;
                    background-size: contain;
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
            &.prev {
                float: left;
                margin-left: 0;
            }
            &.next {
                float: right;
                margin-right: 0;
                a {
                    transform: rotate(180deg);
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        margin: 15px 0;
    }
    @media screen and (max-width: 767px) {
        ul li {
            margin: 5px 10px;
        }
    }
    @media screen and (max-width: 420px) {
        ul li {
            &.prev, &.next {
                display: table;
                float: none;
                margin: 5px auto;
            }
        }
    }
}
/* page pagination */

/* page vacancy */
form {
    display: block;
    max-width: 675px;
    .form__row {
        min-height: 43px; 
        margin-bottom: 18px;  
        .left {
            @include inline-block;
            width: 180px;
            font-size: 15px;
            padding-top: 9px;
        }
        .right {
            @include inline-block;
            width: calc(100% - 180px);
            margin-left: -5px;
            input[type="file"] {
                display: none;
            }
            .g-recaptcha {
                margin-bottom: 15px;
            }
            .file {
                @include inline-block;
                margin-bottom: 15px;
                label {
                    display: block;
                    min-width: 150px;
                    background-color: #0099ec;
                    font-weight: 100;
                    color: #fff;
                    padding: 15px;
                    line-height: 1;
                    cursor: pointer;
                }
            }
            .filename {
                @include inline-block;
                min-height: 0px;
                width: auto;
                padding: 0;
                border: none;
                margin: 10px 0 10px 10px;
            }
            .btn {
                padding: 13px 27px!important;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .form__row {
            margin-bottom: 0px;
        }
    }
    @media screen and (max-width: 680px) {
        .form__row {
            height: auto;
            margin-bottom: 0;
            .left {
                display: block;
                width: 100%;
                margin-bottom: 10px;
            }
            .right {
                display: block;
                width: 100%;
                float: none;
                input[type="text"],
                input[type="email"],
                textarea {
                    margin-bottom: 10px;
                }
                .filename {
                    width: calc(100% - 150px);
                    overflow: hidden;
                }
            }
        }
    }
    @media screen and (max-width: 340px) {
        .form__row {
            .right {
                .g-recaptcha {
                    transform: scale(0.9);
                    transform-origin: 0 50%;
                }
            }
        }
    }
}
/* page vacancy */

/* page contacts */
.contacts {
    .contacts__col {
        @include inline-block;
        width: 200px;
        margin: 0 70px 15px 0;
        .contacts__col__img {
            width: 170px;
            height: 225px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
/*         &:nth-of-type(-n+3) {
    h5 {
        margin-top: 0;
    }
} */
        h5 {
            margin: 20px 0;
        }
        ul {
            @include ul-default;
            padding-left: 10px;
            border-left: 3px solid #000;
            li {
                display: block;
                margin: 7px 0;
                a {
                    display: block;
                    color: #4c4c4c;
                    text-decoration: none;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .contacts__col {
            h5 {
                margin: 10px 0;
            }
            ul {
                li {
                    margin: 5px 0;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .contacts__col {
            margin-right: 35px;
        }
    }
    @media screen and (max-width: 991px) {
        .contacts__col {
            width: 49%;
            margin-right: 0;
            &:nth-child(2n) {
                float: right;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .contacts__col {
            ul {
                border: none;
            }
            h5 {
                margin: 10px 0;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .contacts__col {
            width: 100%;
            text-align: left;
            &:nth-child(2n) {
                float: none;
                text-align: right;
            }
            .contacts__col__img {
                width: 100%;
            }
            ul {
                padding-left: 0;
            }
        }
    }
}
/* page contacts */

/* modal */
.modal {
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
        .close {
            width: 25px;
            height: 25px;
            position: absolute;
            right: 8px;
            top: 10px;
            opacity: 1;
            outline: none;
            &:before, &:after {
                content: '';
                width: 25px;
                height: 2px;
                background-color: #c74a34;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: -1px auto 0;
                transition: 300ms;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 25px;
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}

/* modal */

.mCSB_scrollTools {
    right: -5px;
    opacity: 1;
    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 6px;
            border-radius: 0px;
            background-color: #dbdee4!important;
        }
    }
    .mCSB_draggerRail {
        width: 6px;
        background-color: transparent;
        border-radius: 0px;
    }
}

/* page content */