/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
			margin: -50px auto 0 auto;
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid #3361eb;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3361eb;
				}
			}
			&.slick-active {
				button {
					background-color: #3361eb;
				}
			}
		}
	}
}
/* main slider */

/* main about */
.about {
    height: 100%;
    /* background: url(../img/bg--img.png) repeat-x center bottom; */
    background-size: contain;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    .container {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0px 15px 0px;
        height: 100%;
    }
    .logo--mobile {
        display: none;
    }
    h1 {
        @include inline-block;
        width: 45%;     
        min-height: 170px;
        font-family: $rainTM;
        font-size: 38px;
        text-transform: uppercase;
        text-align: left;
        color: #272727;
        margin: 0;
    }
    .main__logo {
        width: 450px;
        height: 300px;
        opacity: 0;
        margin: 0 auto;
        video, img {
            width: 100%;
        }
    }
    .line {
        display: none;
        position: absolute;
        max-width: 100%;
        img {
            max-width: 100%;
        }
        &.line1 {
            left: -10px;
            top: 0;
        }
        &.line2 {
            top: 10px;
            left: 30px;
        }
        &.line3 {
            top: 65px;
            left: 50px;
        }
        &.line4 {
            left: -10px;
            bottom: 65px;
        }
        &.line5 {
            bottom: 55px;
            left: 30px;
        }
        &.line6 {
            bottom: 0;
            left: -10px;
        }
    }
    @media screen and (max-width: 1440px) {
        .main__logo {
            width: 350px;
            height: 250px;
        }
        .line {
            max-width: 80%;
            &.line2 {
                top: 20px;
            }
            &.line3 {
                top: 35px;
            }
            &.line4 {
                bottom: 35px;
            }
            &.line5 {
                bottom: 20px;
            }
        }
        .container {
            padding: 35px 0;
        }
    }
    @media screen and (max-width: 991px) {
        h1 {
            font-size: 35px;
        }
        .main__logo {
            width: 50%;
            height: auto;
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            padding: 25px 15px 35px;
        }
        h1 {
            font-size: 30px;
        }
        .main__logo {
            width: 40%;
        }
        .line {
            display: none;
        }
    }
    @media screen and (max-width: 680px) {
        position: relative;
        transform: translateY(0);
        top: 0;
        .container {
            display: block;
        }
        h1 {
            display: block;
            min-height: 0px;
            width: 100%;
        }
        .main__logo {
            display: none;
            width: 100%;
            margin: 10px auto; 
        }
        .logo--mobile {
            display: flex;
            height: 100%;
            margin: 0px;
            img {
                width: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
    }
}
/* main about */

/* main content */